const colors = {
  background: "#ffffff",
  text: "#2F4858",
  textHover: "#2F4858",
  primaryDarker: "#7CAF41",
  primaryDark: "#7CAF41",
  primary: "#7CAF41",
  primaryLight: "#7CAF41",
  primaryLighter: "#7CAF41",
  primaryHover: "#7CAF41",
  secondary: "#ff4400",
  secondaryHover: "#ff4400",
  danger: "#dd4b39",
  dangerHover: "#dd4b39",
  warning: "#ff8d24",
  warningHover: "#ff8d24",
  success: "#009F78",
  successHover: "#009F78",
  muted: "#f6f6f9",
  gray: "#A0A9BA",
  grayDark: "#233239",
  grayLight: "#F5F5F5",
  gray50: "rgba(160, 169, 186, 0.5)",
  gray30: "rgba(160, 169, 186, 0.3)",
  gray10: "rgba(160, 169, 186, 0.1)",
  highlight: "rgba(160, 169, 186, 0.5)",
  footer: "#3c3c3c"
};

export default colors;
