import PropTypes from "prop-types";
import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, SiteWrapper } from "../../theme/Site.style";
import { buildTheme } from "../../theme";
import { useStaticQuery, graphql } from "gatsby";
import CookiesBanner from "./CookiesBanner";
import SEO from "./seo";
import PageTitle from "../UI/PageTitle";

import LangProvider from "./langContext";

const Layout = ({
  title,
  description,
  thumbnail,
  lang,
  i18nPaths,
  children,
  raw,
  slug,
  headerImage
}) => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      {
        markdownRemark(frontmatter: { settingName: { eq: "theme" } }) {
          frontmatter {
            breakpoints {
              small
              medium
              large
              huge
            }
            colors {
              background
              danger
              dangerHover
              footer
              gray
              gray10
              gray30
              gray50
              grayDark
              grayLight
              highlight
              muted
              primary
              primaryHover
              primaryDarker
              primaryDark
              primaryLight
              primaryLighter
              secondary
              secondaryHover
              success
              successHover
              text
              textHover
              warning
              warningHover
            }
            font {
              fontname
              fontsizes
            }
            fontheads {
              fontname
              fontsizes
            }
          }
        }
      }
    `
  );
  const themeSettings = markdownRemark.frontmatter;
  const theme = buildTheme(themeSettings);
  raw = raw || false;
  return (
    <LangProvider lang={lang} i18nPaths={i18nPaths}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <SiteWrapper>
          <SEO title={title} description={description} thumbnail={thumbnail} />
          <Navbar path={slug} />
          {slug !== "/" && <PageTitle title={title} headerImage={headerImage} />}
          {children}
          <Footer theme={theme} />
          <CookiesBanner />
        </SiteWrapper>
      </ThemeProvider>
    </LangProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  description: PropTypes.any,
  i18nPaths: PropTypes.any,
  lang: PropTypes.any,
  raw: PropTypes.bool,
  theme: PropTypes.any,
  thumbnail: PropTypes.any,
  title: PropTypes.any,
  headerImage: PropTypes.any,
  slug: PropTypes.any
};

export default Layout;
