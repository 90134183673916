import colors from "./colors";
import { buildButtons } from "./buttons";
import variants from "./variants";
import forms from "./forms";

const theme = {
  colors: colors,
  forms: forms,
  fonts: {
    monospace: "Menlo, monospace"
  },
  fontSizes: [13, 15, 17, 20, 22, 24, 28, 38, 46, 50, 60, 72],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  space: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 120, 140, 160],
  sizes: {
    avatar: 48
  },
  radii: {
    square: 0,
    default: 5,
    big: 8,
    circle: 99999
  },
  shadows: {
    default: "0 10px 20px rgba(100, 84, 70, .1)",
    dark: "0 4px 4px rgba(0, 0, 0, .25)"
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      color: "primary"
    },
    h1: {
      fontSize: 9,
      color: "white"
    },
    h2: {
      fontSize: 8,
      fontWeight: "normal",
      color: "primary"
    },
    h3: {
      fontSize: 6,
      fontWeight: "normal"
    },
    h4: {
      fontSize: 5
    },
    h5: {
      fontSize: 3
    },
    display: {
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7]
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      fontSize: "inherit"
    },
    hero: {
      fontSize: [7, 9, 10],
      color: "white",
      "&:after": {
        display: "none"
      }
    },
    body: {
      fontFamily: "body",
      fontSize: 2,
      lineHeight: "body"
    }
  },
  variants: variants,
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body"
    }
  },
  gutter: 30
};
export default theme;

const buildTheme = settings => {
  theme.fonts = {
    body: settings.font.fontname + ", sans-serif",
    heading: settings.fontheads.fontname + ", sans-serif",
    monospace: "Menlo, monospace"
  };

  theme.colors = {
    ...theme.colors,
    ...settings.colors
  };
  theme.buttons = buildButtons(theme.colors);
  theme.breakpoints = [
    settings.breakpoints.small,
    settings.breakpoints.medium,
    settings.breakpoints.large,
    settings.breakpoints.huge
  ];
  return theme;
};

export { buildTheme };
