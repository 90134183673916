import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass/styled-components";

const Section = props => {
  return (
    <Box width="100%" pt={[12, 12, 13, 15]} pb={[12, 12, 13, 15]} {...props}>
      {props.children}
    </Box>
  );
};

Section.propTypes = {
  children: PropTypes.any
};

export default Section;
