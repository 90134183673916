import PropTypes from "prop-types";
import React from "react";
import { Image, Box } from "rebass/styled-components";
import Img from "gatsby-image";

const CustomImage = ({ img, alt, ...props }) => {
  alt = alt || "";
  img = img || false;
  const image =
    typeof img === "string"
      ? img
      : img.childImageSharp === null || typeof img.childImageSharp === "undefined"
      ? img.publicURL
      : img.childImageSharp;
  if (image) {
    return typeof image === "string" ? (
      <Image src={image} alt={alt} {...props} />
    ) : (
      <Box {...props}>
        {" "}
        <Img {...image} alt={alt} />
      </Box>
    );
  }
  return null;
};

CustomImage.propTypes = {
  alt: PropTypes.string,
  img: PropTypes.any
};
export default CustomImage;
