import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import Container from "../UI/Container";
import Link from "../UI/Link";
import { useStaticQuery, graphql } from "gatsby";
import CustomImage from "../UI/CustomImage";
import LangSwitcher from "../UI/LangSwitcher";
import Drawer from "./Drawer";
import Icon from "../UI/Icon";

const NavbarRaw = ({ menusSettings, footerSettings, logoAlt, path }) => {
  logoAlt = logoAlt || "Logo";
  var telLink = "tel:+687";
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setStiked(position > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  if (!menusSettings.activeTopMenu) {
    return <></>;
  }
  return (
    <Box
      top={0}
      width={1}
      sx={{
        zIndex: 1000,
        position: stiked ? "fixed" : "relative"
      }}
    >
      <Box
        variant="navbar"
        className={stiked ? "sticky" : "sticky-off"}
        bg={path === "/" ? (stiked ? "white" : "transparent") : "white"}
        sx={{
          boxShadow: path === "/" ? (stiked === false ? "none" : "dark") : "dark"
        }}
      >
        <Container variant="containerFluid" pl={[0, 0, 0, 0]} pr={[3, 3, 6, 6, 18]}>
          <Flex alignItems="center" minHeight={["60px", "70px"]}>
            <Box
              sx={{
                display: path === "/" ? (stiked === false ? "none" : "flex") : "flex",
                alignItems: "center",
                alignSelf: "stretch",
                position: "relative",
                pl: [4, 4, 6, 6, 18],
                pr: [2, 2, 4],
                "&:before, &:after": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  left: 0,
                  bg: "primaryDark",
                  zIndex: "1"
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  width: "100%",
                  height: "200%",
                  transform: "skew(-20deg) rotate(5deg)",
                  transformOrigin: "bottom left",
                  bottom: 0
                },
                "&:after": {
                  width: "100px",
                  height: "100%"
                },
                "& > a": {
                  position: "relative",
                  zIndex: "3"
                }
              }}
            >
              <Link href="/" className="logo">
                <CustomImage
                  height="auto"
                  width={["150px", "150px", "180px", "180px", "220px"]}
                  img={path === "/" ? menusSettings.logo2 : menusSettings.logo}
                  alt={logoAlt}
                />
              </Link>
            </Box>
            <Box mx="auto" />
            <Box
              alignItems="center"
              display={["none", "none", "none", "flex"]}
              mr={6}
              sx={{
                a: {
                  display: "flex",
                  alignItems: "center",
                  ml: 6,
                  "button, button:hover": {
                    color: path === "/" ? (stiked ? "primary" : "white") : "primary"
                  }
                },
                ".sub-menu": {
                  ml: 6,
                  a: {
                    ml: 0
                  }
                }
              }}
            >
              {menusSettings.mainmenu
                .filter(item => item.inNavBar)
                .map((item, i) => (
                  <Box key={"menu-" + i} variant="menuItem">
                    <Link activeClassName="active" href={item.path} external={item.external}>
                      <Button variant="ninja">
                        <Text>{item.title}</Text>
                      </Button>
                      {item.mainmenuSubmenu && (
                        <Flex color="secondary" className="submenu-icon" ml={2}>
                          <Icon icon="chevron-down" size={16} />
                        </Flex>
                      )}
                    </Link>
                    {item.mainmenuSubmenu && (
                      <Box className="sub-menu">
                        <Box>
                          {item.mainmenuSubmenu.map((itemSub, index) => (
                            <Box key={"menu-sub-index" + i + "-" + index}>
                              <Link activeClassName="active" href={itemSub.path}>
                                <Button variant="ninja">
                                  <Text>{itemSub.title}</Text>
                                </Button>
                              </Link>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
              <LangSwitcher />
            </Box>
            <Box display={["none", "block"]}>
              <a href={(telLink = telLink + footerSettings.phoneFooter.replace(/[- )(]/g, ""))}>
                <Button
                  variant="primary"
                  sx={{
                    svg: {
                      mr: [0, 2]
                    }
                  }}
                >
                  <Icon icon="phone" size={18} />
                  <Text display={["none", "block"]}>{footerSettings.phoneFooter}</Text>
                </Button>
              </a>
            </Box>
            <Drawer
              placement="right"
              width={["100%", "350px"]}
              closeButton={
                <Box
                  textAlign="right"
                  sx={{
                    svg: {
                      mr: "0 !important"
                    }
                  }}
                >
                  <Button
                    label="close-menu"
                    aria-label="close-menu"
                    variant="ninja"
                    p={3}
                    color="text"
                  >
                    <Icon icon="cross" size={18} />
                  </Button>
                </Box>
              }
              drawerHandler={
                <Button
                  label="open-menu"
                  aria-label="open-menu"
                  variant="ninja"
                  pl={2}
                  display={["flex", "flex", "flex", "none"]}
                  ml={2}
                >
                  <Icon icon="menu" size={24} />
                </Button>
              }
              open={open}
              toggleHandler={toggleHandler}
            >
              <Box bg="background">
                <Box>
                  {menusSettings.mainmenu
                    .filter(item => item.inSideBar)
                    .map((item, i) => (
                      <Box
                        variant="menuItem"
                        key={i}
                        sx={{
                          a: {
                            color: "primary",
                            display: "block",
                            mb: 2,
                            px: 4,
                            py: 2,
                            "button:hover": {
                              color: "primary"
                            }
                          },
                          button: {
                            "&:before": {
                              width: "40px"
                            }
                          }
                        }}
                      >
                        <Link href={item.path} external={item.external} onClick={toggleHandler}>
                          <Button width="100%" textAlign="left" variant="ninja">
                            <Text>{item.title}</Text>
                          </Button>
                        </Link>
                      </Box>
                    ))}
                </Box>
                <Box px={4} mt={6}>
                  <a href={(telLink = telLink + footerSettings.phoneFooter.replace(/[- )(]/g, ""))}>
                    <Button variant="primary">
                      <Icon icon="phone" size={18} />
                      <Text>{footerSettings.phoneFooter}</Text>
                    </Button>
                  </a>
                </Box>
              </Box>
            </Drawer>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

NavbarRaw.propTypes = {
  logoAlt: PropTypes.string,
  path: PropTypes.string,
  menusSettings: PropTypes.shape({
    logo: PropTypes.any,
    logo2: PropTypes.any,
    mainmenu: PropTypes.any,
    activeTopMenu: PropTypes.bool
  }),
  footerSettings: PropTypes.shape({
    phoneFooter: PropTypes.any
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Navbar = ({ theme, path }) => {
  const { allMarkdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: { frontmatter: { title: { in: ["menus", "footer"] } } }) {
          nodes {
            frontmatter {
              title
              activeTopMenu
              mainmenu {
                external
                inNavBar
                inSideBar
                offset
                path
                title
              }
              menutopbartype
              logo2 {
                publicURL
                childImageSharp {
                  fluid(maxHeight: 75, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              logo {
                publicURL
                childImageSharp {
                  fluid(maxHeight: 75, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              phoneFooter
            }
          }
        }
      }
    `
  );
  const menusSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "menus") || {};
  const menusSettings = menusSettingsFrontmatter.frontmatter
    ? menusSettingsFrontmatter.frontmatter
    : {};
  const footerSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "footer") || {};
  const footerSettings = footerSettingsFrontmatter.frontmatter
    ? footerSettingsFrontmatter.frontmatter
    : {};
  return (
    <NavbarRaw
      theme={theme}
      menusSettings={menusSettings}
      footerSettings={footerSettings}
      logoAlt={site.siteMetadata.title}
      path={path}
    />
  );
};

Navbar.propTypes = {
  theme: PropTypes.any,
  path: PropTypes.string
};

export { NavbarRaw };
export default Navbar;
