import React, { useState, useEffect } from "react";
import { Box, Flex, Button } from "rebass/styled-components";
import Container from "../UI/Container";
import Markdown from "../UI/Markdown";
import { useStaticQuery, graphql } from "gatsby";

function loadGTM(my_GTM_id) {
  if (typeof window !== "undefined") {
    if (typeof dataLayer !== "undefined") {
      dataLayer.push({ event: "pageview" });
    } else {
      //you can add checks here to see if GDPR got accepted if you want...
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js"
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtag.js?id=" + i + dl;
        j.onload = function() {
          if (typeof dataLayer !== "undefined") {
            dataLayer.push({ event: "pageview" });
          }
        };
        f.parentNode.insertBefore(j, f);

        // console.log(my_GTM_id, "test");
      })(window, document, "script", "dataLayer", my_GTM_id);
    }
  }
}

function getCookie(cname, defaultValue) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie) || "";
  var ca = typeof decodedCookie === "string" ? decodedCookie.split(";") : false;
  if (ca) {
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return defaultValue;
}
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const CookiesBanner = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { settingName: { eq: "siteinfos" } }) {
          frontmatter {
            gacode
            facebookPixel
            rgpdBanner
          }
        }
      }
    `
  );
  const { gacode, facebookPixel, rgpdBanner } = markdownRemark.frontmatter;
  // const location = useLocation();
  const [onboarding, setOnboarding] = useState(undefined);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof onboarding !== "undefined") {
      setCookie("onboarding", onboarding, 365);
      if (onboarding) {
        // console.log(gacode, "test");
        loadGTM(gacode);
      }
    }
  }, [onboarding]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const onboardingTemp = getCookie("onboarding", undefined);
      setOnboarding(onboardingTemp);
      setLoaded(true);
    }
  }, []);
  return (
    <Box
      variant="cookiesBanner"
      sx={{
        transform:
          typeof onboarding === "undefined" && loaded && (gacode || facebookPixel)
            ? "translate(0px,0%)"
            : "translate(0px,100%)"
      }}
    >
      <Box bg="white" py={4}>
        <Container>
          <Flex
            alignItems="center"
            flexDirection={["column", "column", "row"]}
            paddingBottom={[2, 2, 0]}
          >
            <Markdown pr={[0, 0, 2]}>{rgpdBanner}</Markdown>
            <Box
              pl={[0, 0, 5]}
              sx={{
                flex: 1,
                minWidth: "auto"
              }}
            >
              <Flex pt={[4, 4, 0]}>
                <Button onClick={() => setOnboarding(true)} px={6} variant="primary">
                  J&#39;accepte
                </Button>
                <Button variant="base" onClick={() => setOnboarding(false)} ml={4}>
                  Refuser
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default CookiesBanner;
