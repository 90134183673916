const buildButtons = colors => {
  const buttons = {};

  const bigProps = {
    fontSize: 2,
    px: 6,
    py: 3
  };
  const smallProps = {
    fontSize: "0.7em",
    px: 2,
    py: 1
  };
  const buttonColors = ["primary", "secondary", "success", "danger", "warning", "text"];

  for (var i = 0; i < buttonColors.length; i++) {
    let buttonColor = buttonColors[i];
    let gradiantColor = colors[buttonColor + "Gradiant"] || colors[buttonColor + "Hover"];
    buttons[buttonColor] = {
      variant: "buttons.base",
      backgroundColor: buttonColor === "primary" ? "primaryDark" : buttonColor,
      borderColor: buttonColor === "primary" ? "primaryDark" : buttonColor,
      color: "white",
      "&:hover": {
        color: buttonColor === "primary" ? "secondary" : "white",
        bg: buttonColor === "primary" ? "primary" : buttonColor + "Hover",
        borderColor: buttonColor === "primary" ? "primary" : buttonColor + "Hover"
      }
    };
    buttons[buttonColor + "Small"] = {
      ...buttons[buttonColor],
      ...smallProps
    };

    buttons[buttonColor + "Big"] = {
      ...buttons[buttonColor],
      ...bigProps
    };
    buttons[buttonColor + "Outline"] = {
      variant: "buttons.base",
      borderColor: buttonColor,
      color: buttonColor,
      bg: "transparent",
      "&:hover": {
        bg: "transparent",
        borderColor: buttonColor + "Hover",
        color: buttonColor + "Hover"
      }
    };
    buttons[buttonColor + "OutlineSmall"] = {
      ...buttons[buttonColor + "Outline"],
      ...smallProps
    };

    buttons[buttonColor + "OutlineBig"] = {
      ...buttons[buttonColor + "Outline"],
      ...bigProps
    };
    buttons[buttonColor + "OutlinePlain"] = {
      variant: "buttons.base",
      borderColor: buttonColor,
      color: buttonColor,
      bg: "transparent",
      "&:hover": {
        bg: buttonColor + "Hover",
        borderColor: buttonColor + "Hover",
        color: "white"
      }
    };
    buttons[buttonColor + "OutlinePlainSmall"] = {
      ...buttons[buttonColor + "OutlinePlain"],
      ...smallProps
    };

    buttons[buttonColor + "OutlinePlainBig"] = {
      ...buttons[buttonColor + "OutlinePlain"],
      ...bigProps
    };
    buttons[buttonColor + "Gradiant"] = {
      variant: "buttons.base",
      backgroundSize: "200% 200%",
      backgroundPosition: "left 50%",
      backgroundRepeat: "repeat-x",
      "&:hover": { backgroundPosition: "right 50%" },
      backgroundColor: buttonColor,
      backgroundImage:
        "linear-gradient(90deg, " +
        gradiantColor +
        " , " +
        colors[buttonColor] +
        " 50%," +
        gradiantColor +
        ");"
    };
    buttons[buttonColor + "GradiantSmall"] = {
      ...buttons[buttonColor + "Gradiant"],
      ...smallProps
    };
    buttons[buttonColor + "GradiantBig"] = {
      ...buttons[buttonColor + "Gradiant"],
      ...bigProps
    };
  }

  buttons.base = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "1",
    fontSize: 2,
    px: 4,
    py: "12px",
    fontWeight: "normal",
    color: "primary",
    bg: "transparent",
    borderRadius: "default",
    border: "1px solid",
    borderColor: "primaryLight",
    cursor: "pointer",
    transition: "all 300ms ease",
    "&:hover": {
      color: "white",
      bg: "primaryLight",
      borderColor: "primaryLight"
    },
    svg: {
      mr: 2,
      fill: "secondary"
    },
    "div + svg": {
      mr: 0,
      ml: 2
    }
  };
  buttons.baseTrans = {
    variant: "buttons.base",
    bg: "transparent",
    color: "white"
  };
  // buttons.primary = {
  //   variant: "buttons.base",
  //   color: "white",
  //   bg: "primaryDark",
  //   borderColor: "primaryDark",
  //   "&:hover": {
  //     color: "secondary",
  //     borderColor: "primaryHover",
  //     bg: "primaryHover"
  //   }
  // };
  buttons.ninja = {
    variant: "buttons.base",
    cursor: "pointer",
    color: "inherit",
    bg: "transparent",
    borderColor: "transparent",
    border: "none",
    p: 0,
    "&:hover": {
      color: "transparent",
      bg: "transparent",
      borderColor: "transparent",
      opacity: 1
    }
  };
  return buttons;
};
export { buildButtons };
