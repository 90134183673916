import PropTypes from "prop-types";
import React from "react";

const Icon = ({ icon, size, useCurrentColor, ...props }) => {
  const SvgItem2 = require(`../../../static/img/icons/${icon}.svg`);

  const svgprops = {};
  if (useCurrentColor) {
    svgprops.fill = "currentColor";
    svgprops.stroke = "none";
  }
  if (typeof SvgItem2 === "function") {
    return <SvgItem2 height={size + "px"} width={size + "px"} {...svgprops} {...props} />;
  }
  if (typeof SvgItem2 === "string") {
    return <img src={SvgItem2} style={{ height: size + "px", width: size + "px" }} {...props} />;
  }
  return <></>;
};

Icon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.number,
  useCurrentColor: PropTypes.bool
};
Icon.defaultProps = {
  size: 16,
  icon: "home",
  useCurrentColor: true
};

export default Icon;
