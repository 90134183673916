const variants = {
  avatar: {
    width: "avatar",
    height: "avatar",
    borderRadius: "circle"
  },
  badges: {
    display: "inline-block",
    px: 2,
    py: 1,
    borderRadius: 9999,
    fontSize: 0
  },
  badge: {
    primary: {
      variant: "variants.badges",
      color: "white",
      bg: "primary"
    }
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    fontWeight: "inherit"
  },
  linktext: {
    color: "inherit",
    textDecoration: "inherit"
  },
  darkBg: {
    backgroundColor: "primary",
    color: "background"
  },
  bgImg: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  squareBox: {
    position: "relative",
    "&:after": {
      content: "''",
      display: "block",
      paddingBottom: "100%"
    }
  },
  nav: {
    fontSize: 1,
    fontWeight: "bold",
    display: "inline-block",
    p: 2,
    color: "inherit",
    textDecoration: "none",
    ":hover,:focus,.active": {
      color: "primary"
    }
  },
  cookiesBanner: {
    position: "fixed",
    bottom: "0",
    left: 0,
    right: 0,
    zIndex: 99,
    transition: "all 300ms ease",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)"
  },
  formItemError: {
    "input,textarea,select": {
      borderColor: "danger"
    },
    ".checkit": {
      color: "danger"
    }
  },
  navbar: {
    position: "absolute",
    width: ["100%"],
    minHeight: ["60px", "70px"],
    transition: "all 300ms ease",
    zIndex: 10,
    ".logo": {
      display: "block",
      lineHeight: 0
    }
  },
  container: {
    width: "100%",
    maxWidth: ["100%", "100%", "960px", "1290px"],
    m: "0 auto",
    flexbox: true,
    px: [3, 3, 6]
  },
  containerFluid: {
    width: "100%",
    m: "0 auto",
    flexbox: true,
    px: [3, 3, 6]
  },
  grid: {
    flexWrap: "wrap",
    mx: [-2, -3, -3]
  },
  gridItem: {
    px: [2, 3, 3]
  },
  gridLarge: {
    flexWrap: "wrap",
    mx: [-4, -6, -6]
  },
  gridItemLarge: {
    px: [4, 6, 6]
  },
  section: {
    width: "100%",
    pt: [10, 10, 11],
    pb: [10, 10, 11]
  },
  card: {
    bg: "primaryLighter",
    borderRadius: "big",
    overflow: "hidden",
    p: 6,
    transition: "all 300ms ease",
    "& > div": {
      transform: "translateX(0)",
      transition: "all 300ms ease",
      h3: {
        transition: "all 300ms ease"
      }
    },
    "&:hover": {
      boxShadow: "default",
      bg: "white",
      "& > div": {
        transform: "translateX(5px)",
        h3: {
          color: "secondary"
        }
      }
    }
  },
  menuItem: {
    position: "relative",
    button: {
      position: "relative",
      "& > div": {
        position: "relative",
        zIndex: 2
      },
      "&:before": {
        content: '""',
        position: "absolute",
        bottom: "0",
        left: "0",
        transform: "translateY(20px)",
        width: "100%",
        height: "5px",
        bg: "secondary",
        opacity: "0",
        transition: "all 300ms ease",
        zIndex: -1
      }
    },
    "& > a:hover": {
      "button:before": {
        opacity: "1",
        transform: "translateY(0)",
        zIndex: 1
      }
    },
    "a.active": {
      button: {
        "&:before": {
          opacity: "1",
          transform: "translateY(0)",
          zIndex: 1
        }
      }
    },
    ".sub-menu": {
      bg: "white",
      position: "absolute",
      top: "100%",
      left: 0,
      pt: 2,
      zIndex: 10,
      transition: "all 300ms ease",
      transform: "translateY(20px)",
      opacity: "0",
      visibility: "hidden",
      "& > div": {
        boxShadow: "dark",
        "& > div:not(:first-child)": {
          borderWidth: "1px 0 0 0",
          borderColor: "primaryDark",
          borderStyle: "solid"
        }
      },
      a: {
        display: "block",
        bg: "primary",
        button: {
          whiteSpace: "nowrap",
          py: 2,
          px: 3,
          borderRadius: 0,
          color: "white",
          width: "100%",
          fontSize: 1,
          justifyContent: "flex-start",
          "&:before": {
            bg: "secondary",
            width: "20%",
            height: "3px",
            display: "none"
          }
        },
        "&:hover": {
          button: {
            color: "secondary"
          }
        },
        "&.active": {
          button: {
            bg: "primaryDark"
          }
        }
      }
    },
    "&:hover": {
      ".sub-menu": {
        visibility: "visible",
        opacity: "1",
        transform: "translateY(0)"
      }
    }
  },
  skewBefore: {
    position: "relative",
    "&:before": {
      content: '""',
      display: "block",
      height: 0,
      width: 0,
      borderWidth: "0",
      borderBottomWidth: ["10px", "20px", "30px"],
      borderLeftWidth: "100vw",
      borderStyle: "solid",
      borderColor: "transparent",
      position: "absolute",
      bottom: "100%",
      left: "0",
      borderBottomColor: "white"
    }
  },
  skewBoth: {
    position: "relative",
    "&:before, &:after": {
      content: '""',
      display: "block",
      height: 0,
      width: 0,
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "transparent",
      position: "absolute",
      left: "0"
    },
    "&:before": {
      borderBottomWidth: ["10px", "20px", "30px"],
      borderLeftWidth: "100vw",
      bottom: "100%"
    },
    "&:after": {
      borderTopWidth: ["10px", "20px", "30px"],
      top: "100%",
      zIndex: 1
    }
  },
  horizontalCard: { variant: "variants.card" },
  verticalCard: { variant: "variants.card" }
};

export default variants;
