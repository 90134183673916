import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Box, Button, Text } from "rebass/styled-components";
import { LangContext } from "../Layout/langContext";
import { useStaticQuery, graphql } from "gatsby";
const LangSwitcher = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { settingName: { eq: "siteinfos" } }) {
        frontmatter {
          locales
        }
      }
    }
  `);
  const siteinfos = data.markdownRemark.frontmatter;
  const { currentLanguage, setCurrentLanguage } = useContext(LangContext);
  if (siteinfos.locales.length > 1) {
    const sx = {
      position: "relative",
      bg: "white",
      overflow: "hidden",
      display: "flex",
      flexDirection: ["row", "row", "row", "column"],
      a: {
        display: "block",
        py: 1,
        px: 2,
        mx: [1, 1, 1, 0],
        width: ["auto", "auto", "auto", "100px"],
        borderWidth: ["1px", "1px", "1px", "0"],
        borderStyle: "solid",
        borderColor: "grayLight",
        transition: "200ms background ease",
        "&:not(.active)": {
          position: ["initial", "initial", "initial", "absolute"],
          opacity: ".6",
          top: "100%",
          left: 0
        },
        button: {
          display: "flex",
          alignItems: "center",
          img: {
            display: "block",
            width: "30px",
            height: "auto",
            mr: 2
          },
          div: {
            whiteSpace: "nowrap",
            fontWeight: 600
          }
        }
      },
      "&:hover": {
        boxShadow: ["none", "none", "none", "headerLight"],
        a: {
          position: "initial",
          "&:hover": {
            opacity: "1",
            borderColor: ["gray", "gray", "gray", "transparent"]
          }
        }
      }
    };
    return (
      <Box sx={sx}>
        {siteinfos.locales
          .filter(locale => locale === currentLanguage)
          .map((locale, i) => (
            <Button key={i} as="a" className="active" onClick={() => setCurrentLanguage(locale)}>
              <Button variant="ninja">
                <Text>{locale}</Text>
              </Button>
            </Button>
          ))}
        {siteinfos.locales
          .filter(locale => locale !== currentLanguage)
          .map((locale, i) => (
            <Button key={i} as="a" onClick={() => setCurrentLanguage(locale)}>
              <Button variant="ninja">
                <Text>{locale}</Text>
              </Button>
            </Button>
          ))}
      </Box>
    );
  }
  return <></>;
};

LangSwitcher.propTypes = {
  lang: PropTypes.any
};

export default LangSwitcher;
