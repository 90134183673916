import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Box } from "rebass/styled-components";
const Markdown = ({ children, ...props }) => {
  if (children && typeof children.valueOf() === "string") {
    children = children || "";
    var re = /\\\n/gi;
    children = children.replace(re, "  \n");
    return (
      <Box
        sx={{
          a: {
            textDecoration: "underline",
            "&:hover": {
              textDecoration: "none",
              bg: "secondary"
            }
          },
          ul: {
            pl: 4,
            listStyle: "none",
            li: {
              display: "flex",
              "&:before": {
                content: "'•'",
                mr: 2,
                color: "secondary"
              }
            }
          }
        }}
        {...props}
      >
        <ReactMarkdown source={children} />
      </Box>
    );
  }
  return <></>;
};

Markdown.propTypes = {
  children: PropTypes.string
};
export default Markdown;
