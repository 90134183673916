import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass/styled-components";

const Container = ({ fluid, children, maxContainer, ...props }) => {
  fluid = fluid || false;
  return (
    <Box variant={fluid ? "containerFluid" : "container"} {...props}>
      <Box
        className="container-inner"
        maxWidth={maxContainer ? "1100px" : "100%"}
        mx={maxContainer ? "auto" : "0"}
      >
        {children}
      </Box>
    </Box>
  );
};

Container.propTypes = {
  children: PropTypes.any,
  maxContainer: PropTypes.any,
  fluid: PropTypes.bool
};

export default Container;
