import PropTypes from "prop-types";
import React, { useState } from "react";
import { withTheme } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Section from "../UI/Section";
import Container from "../UI/Container";
import CustomImage from "../UI/CustomImage";
import Link from "../UI/Link";
import Icon from "../UI/Icon";
import Markdown from "../UI/Markdown";
import { Box, Flex, Text, Button } from "rebass/styled-components";

const FooterRaw = ({ theme, menusSettings, footerSettings, logoAlt }) => {
  logoAlt = logoAlt || "Logo";
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };
  var telLink = "tel:+687";
  var mailTo = "mailto:";

  if (!menusSettings.activeFooter) {
    return <></>;
  }
  return (
    <Section
      bg="footer"
      color="#fff"
      py={[0, 0, 0, 0]}
      variant="skewBefore"
      sx={{
        "&:before": {
          borderBottomColor: "footer"
        },
        "a:hover, button:hover": { color: "secondary" }
      }}
    >
      <Container pt={6} maxContainer={true}>
        <Flex variant="grid" flexDirection={["column", "column", "row"]} flexWrap="wrap">
          <Box variant="gridItem" width={["100%", "100%", "100%", 1 / 3, 2 / 5]} py={6}>
            <Box mb={6}>
              <CustomImage
                img={footerSettings.logoFooter}
                height="auto"
                width={["150px", "150px", "180px", "180px", "220px"]}
                mx={[0, 0, 0]}
              />
            </Box>
            <Flex alignItems="center" mb={2}>
              <Flex alignItems="center" color="secondary" pr={2}>
                <Icon icon="clock" size={20} />
              </Flex>
              <Text fontWeight="bold">Horaires d’ouverture</Text>
            </Flex>
            <Box fontSize={1}>
              <Markdown>{footerSettings.opentimeFooter}</Markdown>
            </Box>
          </Box>
          <Box variant="gridItem" width={["100%", "100%", "100%", 2 / 3, 3 / 5]} py={6}>
            <Flex
              justifyContent={["center", "space-between"]}
              flexDirection={["column", "row"]}
              flexWrap="wrap"
            >
              <Box pb={1}>
                <Text variant="h5" fontWeight="bold" mb={4} sx={{ textTransform: "uppercase" }}>
                  {"Styl'wood"}
                </Text>
                {menusSettings.footermenumain.map((item, i) => (
                  <Box mt={3} textAlign={["left", "left", "left"]} key={"menu-" + i}>
                    <Link activeClassName="active" href={item.path} external={item.external}>
                      <Button variant="ninja">
                        <Text>{item.title}</Text>
                      </Button>
                    </Link>
                  </Box>
                ))}
              </Box>
              <Box pb={10}>
                <Text
                  variant="h5"
                  fontWeight="bold"
                  mb={4}
                  sx={{
                    textTransform: "uppercase",
                    color: "#281D13",
                    display: ["none", "block", "block", "block"] // Masque en dessous de 576 pixels
                  }}
                >
                  &nbsp;{" "}
                  {/* Utilisation d'un espace pour conserver la structure, mais masqué en mobile */}
                </Text>

                {menusSettings.footermenuprojects.map((item, i) => (
                  <Box mt={3} textAlign={["left", "left", "left"]} key={"menu-" + i}>
                    <Link activeClassName="active" href={item.path} external={item.external}>
                      <Button variant="ninja">
                        <Text>{item.title}</Text>
                      </Button>
                    </Link>
                  </Box>
                ))}
              </Box>
              <Box sx={{ a: { textDecoration: "underline" } }}>
                <Text variant="h5" fontWeight="bold" mb={4} sx={{ textTransform: "uppercase" }}>
                  Contactez-nous
                </Text>
                <Flex alignItems="center" mb={4}>
                  <Flex alignItems="center" color="secondary" pr={2}>
                    <Icon icon="phone" size={20} />
                  </Flex>
                  <a
                    href={
                      (telLink =
                        telLink +
                        footerSettings.phoneFooter.replace(/[- )(]/g, "").replace("+687", ""))
                    }
                  >
                    <Markdown>{footerSettings.phoneFooter}</Markdown>
                  </a>
                </Flex>
                <Flex>
                  <Box color="secondary" pt={1} pr={2}>
                    <Icon icon="poi" size={20} />
                  </Box>
                  <Markdown>{footerSettings.addressFooter}</Markdown>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>

      <Box bg="primaryDarker">
        <Container>
          <Flex
            justifyContent={["center", "center", "space-between"]}
            flexDirection={["column", "column", "row-reverse"]}
            alignItems="center"
            py={3}
            sx={{
              a: {
                "&:hover": {
                  color: "secondary"
                }
              }
            }}
          >
            <Flex
              justifyContent={["center", "center", "flex-start"]}
              flexDirection={["column", "row"]}
              alignItems="center"
            >
              {menusSettings.footermenu.map((item, i) => (
                <Box
                  ml={[0, 3, 0]}
                  mr={[0, 3, 6]}
                  mb={[3, 0, 0]}
                  key={"menu-" + i}
                  sx={{ "a.active": { color: "secondary" } }}
                >
                  <Link activeClassName="active" href={item.path} external={item.external}>
                    <Button variant="ninja">
                      <Text fontSize={0}>{item.title}</Text>
                    </Button>
                  </Link>
                </Box>
              ))}
            </Flex>
            <Box fontSize={0} mt={[6, 6, 0]}>
              <>
                © Styl’Wood 2022 - Réalisation :{" "}
                <a href="https://la-fabrik.nc">
                  <strong>La Fabrik</strong>
                </a>
              </>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Section>
  );
};

FooterRaw.propTypes = {
  logoAlt: PropTypes.string,
  footerSettings: PropTypes.shape({
    addressFooter: PropTypes.any,
    logoFooter: PropTypes.any,
    phoneFooter: PropTypes.any,
    mailFooter: PropTypes.any,
    opentimeFooter: PropTypes.any
  }),
  menusSettings: PropTypes.shape({
    mainmenu: PropTypes.array,
    footermenu: PropTypes.array,
    footermenuen: PropTypes.array,
    footermenumain: PropTypes.array,
    footermenuprojects: PropTypes.array,
    activeFooter: PropTypes.bool
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Footer = ({ theme }) => {
  const { allMarkdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: { frontmatter: { title: { in: ["menus", "footer"] } } }) {
          nodes {
            frontmatter {
              title
              activeFooter
              mainmenu {
                offset
                path
                title
                external
              }
              footermenu {
                offset
                path
                title
                external
              }
              footermenumain {
                offset
                path
                title
                external
              }
              footermenuprojects {
                offset
                path
                title
                external
              }
              logoFooter {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 300, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              addressFooter
              phoneFooter
              mailFooter
              opentimeFooter
            }
          }
        }
      }
    `
  );

  const menusSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "menus") || {};
  const menusSettings = menusSettingsFrontmatter.frontmatter
    ? menusSettingsFrontmatter.frontmatter
    : {};
  const footerSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "footer") || {};
  const footerSettings = footerSettingsFrontmatter.frontmatter
    ? footerSettingsFrontmatter.frontmatter
    : {};
  return (
    <FooterRaw
      theme={theme}
      menusSettings={menusSettings}
      footerSettings={footerSettings}
      logoAlt={site.siteMetadata.title}
    />
  );
};

export default withTheme(Footer);
