const baseItem = {
  color: "text",
  borderColor: "primaryLight",
  borderWidth: "1px",
  borderRadius: "default",
  bg: "white",
  "::placeholder": {
    color: "gray"
  }
};

const forms = {
  input: baseItem,
  select: baseItem,
  textarea: baseItem,
  text: { color: "text" },
  label: {
    pb: 2,
    fontWeight: "bold",
    color: "primaryDark",
    ".required-mark": {
      color: "danger",
      ml: 1
    }
  },
  radio: { mr: 2 },
  checkbox: { mr: 2 }
};

export default forms;
